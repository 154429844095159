module services {
    export module costing {

        export class costParameterRouteService implements interfaces.costing.ICostParameterRouteService {

            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getRouteList(): ng.resource.IResourceClass<interfaces.costing.ICostRoute> {
                return this.$resource<interfaces.costing.ICostRoute>(this.ENV.DSP_URL + "CostParameterRoute/GetRouteList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    routeId: '@routeId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            saveRoute(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterRoute/SaveRoute", {
                });
            }

            removeRoute(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterRoute/RemoveRoute", {
                    cpuId: '@cpuId',
                });
            }


        }

    }

    angular.module("app").service("costParameterRouteService", services.costing.costParameterRouteService);
}